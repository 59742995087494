import { DoubleLeftOutlined } from "@ant-design/icons";
import { Button, Checkbox, Popover, Skeleton } from "antd";
import "dayjs/locale/fr";
import {
  filterView1Helper,
  filterView2Helper,
  filterView3Helper,
  filterViewOthersHelper,
} from "helpers/client/clientMissingDocHelper";
import { useEffect, useState } from "react";
import isOperationEmpty from "../../../../utils/isOperationEmpty";
import Type from "./desktop/Type";
import ClientMissingOthers from "./others";
import ClientMissingTables from "./responsive/tablesResponsive";
import ClientBodyStats from "./statistics";

function ClientMissingBody(props) {
  const [view, setView] = useState(1);
  const [closeDateIsSelected, setCloseDateIsSelected] = useState([true, true, true]);
  const [filteredMissingDocOther, setFilteredMissingDocOther] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [allExpanded, setAllExpanded] = useState(false);
  const [hideDone, setHideDone] = useState(false);

  useEffect(() => {
    setView(props.missingDocDefaultView ?? 1);
  }, [props.missingDocDefaultView]);

  useEffect(() => {
    if (!props.missingDocument) return;
    setAllExpanded(
      props.missingDocument.map(({ operations }) => operations).flat(1).length <
        process.env.REACT_APP_EXPAND_LIMIT
    );
  }, [props.missingDocument]);

  useEffect(() => {
    if (!props.missingDocument) return;
    let missingDoc;
    (async () => {
      let data = [];
      if (hideDone) {
        data = props.missingDocument.map((account) => ({
          ...account,
          operations: account.operations.filter((op) => isOperationEmpty(op)),
        }));
      } else {
        data = props.initMissingDoc;
      }
      switch (view) {
        case 1:
          missingDoc = filterView1Helper(data, closeDateIsSelected, props.fiscalStartMonth);
          break;
        case 2:
          missingDoc = filterView2Helper(data, closeDateIsSelected, props.fiscalStartMonth);
          break;
        default:
          missingDoc = filterView3Helper(data, closeDateIsSelected, props.fiscalStartMonth);
      }
      setDataSource(missingDoc);
    })();
  }, [props.missingDocument, closeDateIsSelected, view, hideDone]);

  useEffect(() => {
    if (!props.missingDocumentOther) return;
    (async () => {
      var data = [];
      if (hideDone) {
        data = props.missingDocumentOther.map((elt) => ({
          ...elt,
          operations: elt.operations.filter((op) => isOperationEmpty(op)),
        }));
      } else {
        data = props.initMissingDocOther;
      }
      const missingDocOther = filterViewOthersHelper(
        data,
        closeDateIsSelected,
        props.fiscalStartMonth
      );
      setFilteredMissingDocOther(missingDocOther);
    })();
  }, [props.missingDocumentOther, closeDateIsSelected, hideDone]);

  const onChange = (e) => {
    setHideDone(e.target.checked);
  };

  return (
    <section id="client-missing">
      {props.missingDocHeaderMessage ? (
        <span dangerouslySetInnerHTML={{ __html: props.missingDocHeaderMessage }} />
      ) : (
        <span>
          <p>
            Bonjour
            <br />
            Voici la liste des mouvements pour lesquels un justificatif est manquant. Nous vous
            invitons à transmettre la pièce correspondante et à indiquer la nature du mouvement le
            cas échéant.
          </p>
        </span>
      )}
      {dataSource.length === 0 || !filteredMissingDocOther ? (
        <Skeleton active />
      ) : (
        <ClientBodyStats
          {...props}
          missingDocument={dataSource}
          missingDocumentOther={filteredMissingDocOther.data}
          lastMailSent={props.lastMailSent}
          closeDateIsSelected={closeDateIsSelected}
          setCloseDateIsSelected={setCloseDateIsSelected}
          view={view}
          setView={setView}
          screenWidth={props.screenWidth}
        />
      )}
      <div className="filter-op-done">
        <Checkbox onChange={onChange}>Masquer les opérations traitées</Checkbox>
      </div>

      {!filteredMissingDocOther ? (
        <Skeleton active />
      ) : (
        <ClientMissingOthers
          missingDocumentOther={filteredMissingDocOther.data}
          setMissingDocumentOther={props.setMissingDocumentOther}
          setInitMissingDocOther={props.setInitMissingDocOther}
          screenWidth={props.screenWidth}
          tokenClient={props.tokenClient}
          fileSettings={props.fileSettings}
        />
      )}
      {!dataSource ? (
        <Skeleton active />
      ) : props.screenWidth <= 900 ? (
        dataSource.map((item) => (
          <ClientMissingTables
            key={item.id}
            type={item.id}
            missingDocument={item.data}
            setMissingDocument={props.setMissingDocument}
            setInitMissingDoc={props.setInitMissingDoc}
            label={item.label}
            tokenClient={props.tokenClient}
            missingDocLostMessage={props.missingDocLostMessage}
            fileSettings={props.fileSettings}
          />
        ))
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Popover
              placement="top"
              content={allExpanded ? "Tout replier" : "Tout déplier"}
              overlayClassName="navbar-popover-overlay"
            >
              <Button
                className="expand-all-icon"
                icon={
                  <DoubleLeftOutlined
                    className={allExpanded ? "expand-icon-down" : "expand-icon-up"}
                  />
                }
                shape="circle"
                onClick={() => setAllExpanded(!allExpanded)}
              />
            </Popover>
          </div>

          {dataSource.map((item) => (
            <Type
              key={item.id}
              id={item.id}
              view={view}
              missingDocument={item.data}
              setMissingDocument={props.setMissingDocument}
              setInitMissingDoc={props.setInitMissingDoc}
              label={item.label}
              tokenClient={props.tokenClient}
              missingDocLostMessage={props.missingDocLostMessage}
              fileSettings={props.fileSettings}
              allExpanded={allExpanded}
            />
          ))}
        </>
      )}
    </section>
  );
}

export default ClientMissingBody;
