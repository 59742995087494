import {
  CheckOutlined,
  ExclamationCircleOutlined,
  EyeFilled,
  InfoCircleOutlined,
  MailOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  QuestionOutlined,
  RedoOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  TimePicker,
} from "antd";
import fr_FR from "antd/lib/date-picker/locale/fr_FR";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { animated, useSpring } from "react-spring";
import * as API from "../../api/API";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import { selectClient, updateClient } from "../../slices/clientSlice";
import { selectUser } from "../../slices/userSlice";
import * as generateMailText from "../../utils/generateMailText";
import logAction from "../../utils/logActions";
import openNotification from "../../utils/notification";
import "./Mail.css";
import MailSettings from "./MailSettings";
import TextEditor from "./TextEditor";

const { confirm } = Modal;

const settings = [
  {
    type: "missingdoc",
    visible: false,
    refresh: false,
  },
  {
    type: "outstandingsclients",
    visible: false,
    refresh: false,
  },
  {
    type: "outstandingsproviders",
    visible: false,
    refresh: false,
  },
];

function MailUnique(props) {
  const dispatch = useDispatch();

  const accountingFirm = useSelector(selectAccountingFirm);
  const user = useSelector(selectUser);
  const client = useSelector(selectClient);
  const [form] = Form.useForm();

  const [componentSize, setComponentSize] = useState("default");
  const [editSubject, setEditSubject] = useState(true);
  const [showEditSubject, setShowEditSubject] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [subject, setSubject] = useState("");
  const [editShowSecond, setEditShowSecond] = useState(false);
  const [addCC, setCC] = useState(
    props.type === "missingdoc"
      ? client.email?.[props.type]?.cc?.length > 0
      : client.email?.["outstandings" + props.type]?.cc?.length > 0
  );
  const [intro, setIntro] = useState("");
  const [editIntro, setEditIntro] = useState(true);
  const [introSecond, setIntroSecond] = useState("test");
  const [link, setLink] = useState("");
  const [editIntroSecond, setEditIntroSecond] = useState(true);
  const [recepientAlert, setRecepientAlert] = useState(false);
  const [ccAlert, setCcAlert] = useState(false);
  const [logo, setLogo] = useState();
  const [loading, setLoading] = useState(false);
  const [testSkeleton, setTestSkeleton] = useState(false);
  const [buttonData, setButtonData] = useState();
  const [hasEdit, setHasEdit] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [refreshMail, setRefreshMail] = useState(false);
  const [signatureImage, setSignatureImage] = useState(null);

  const clientId = props.match.params.clientId;
  const clientRemoteId = props.match.params.clientRemoteId;

  // Reminder mail
  const [showCheckRelanceMail, setShowCheckRelanceMail] = useState(false);
  const [displayRelanceMail, setDisplayRelanceMail] = useState(false);

  const [subjectReminder, setSubjectReminder] = useState("");
  const [editSubjectReminder, setEditSubjectReminder] = useState(true);
  const [showEditSubjectReminder, setShowEditSubjectReminder] = useState(false);

  const [beginingReminder, setBeginingReminder] = useState("");
  const [editBeginingReminder, setEditBeginingReminder] = useState(true);
  const [editBeginingReminderShow, setEditBeginingReminderShow] = useState(false);

  const [endingReminder, setEndingReminder] = useState("");
  const [editEndingReminder, setEditEndingReminder] = useState(true);
  const [editEndingReminderShow, setEditEndingReminderShow] = useState(false);

  // End Reminder mail

  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };

  const checkEmail = (e, inputType) => {
    const text = e.target.value;
    const regex = /^(([a-zA-Z\-0-9\\.]+@)([a-zA-Z\-0-9\\.]+)[;]*)+$/g;

    switch (inputType) {
      case "recepient":
        setRecepientAlert(!regex.test(text));
        break;
      case "cc":
        setCcAlert(!regex.test(text));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setHasEdit(false);
    if (props.type !== "analysis") {
      const settingsData = [
        ...settings.filter((elt) => !elt.type.includes(props.type)),
        {
          type: settings.find((elt) => elt.type.includes(props.type))?.type,
          visible: true,
          refresh: true,
        },
      ];
      setSubject(
        generateMailText.generateSubject(props, accountingFirm, client, user, settingsData)
      );
      setIntro(
        generateMailText.generateBegining(props, accountingFirm, client, user, settingsData)
      );
      setIntroSecond(
        generateMailText.generateEnding(props, accountingFirm, client, user, settingsData)
      );

      setLink(generateMailText.generateLinkText(settingsData));
    }
  }, [props.type]);

  useEffect(() => {
    if (buttonData || refreshMail) {
      setSubject(generateMailText.generateSubject(props, accountingFirm, client, user, buttonData));
      setIntro(generateMailText.generateBegining(props, accountingFirm, client, user, buttonData));
      setIntroSecond(
        generateMailText.generateEnding(props, accountingFirm, client, user, buttonData)
      );

      setLink(generateMailText.generateLinkText(buttonData));

      if (
        buttonData.find((elt) => elt.type === "missingdoc").visible === true &&
        buttonData.find((elt) => elt.type === "missingdoc").refresh === true
      ) {
        setShowCheckRelanceMail(true);
      } else {
        setDisplayRelanceMail(false);
        setShowCheckRelanceMail(false);
      }
    }
    setRefreshMail(false);
  }, [buttonData, refreshMail]);

  useEffect(() => {
    if (accountingFirm.logoFile) {
      (async () => {
        let res = await API.getAccountingFirmLogo(accountingFirm._id);
        if (res.status === 200) {
          const data = await res.blob();
          setLogo(URL.createObjectURL(data));
        } else {
          console.log("error");
        }
      })();
    }
  }, [accountingFirm.logoFile, accountingFirm._id]);

  useEffect(() => {
    if (user.signatureImage) {
      (async () => {
        let res = await API.getUserSignatureImage(user._id, 1);
        if (res.status === 200) {
          const data = await res.blob();
          setSignatureImage(URL.createObjectURL(data));
        } else {
          console.log("Error getUserSignatureImage");
        }
      })();
    }
  }, [user._id]);

  useEffect(() => {
    if (showCheckRelanceMail === false) {
      setDisplayRelanceMail(false);
    }
  }, [showCheckRelanceMail]);

  useEffect(() => {
    if (displayRelanceMail) {
      const settingsData = [
        {
          type: "missingdocreminder",
          visible: true,
          refresh: true,
        },
      ];
      setSubjectReminder(
        generateMailText.generateSubject(props, accountingFirm, client, user, settingsData)
      );
      setBeginingReminder(
        generateMailText.generateBegining(props, accountingFirm, client, user, settingsData)
      );
      setEndingReminder(
        generateMailText.generateEnding(props, accountingFirm, client, user, settingsData)
      );

      //setLink(generateMailText.generateLinkText(settingsData));
    }
  }, [displayRelanceMail]);

  const onSubmit = async (data) => {
    window.scroll(0, 0);
    setLoading(true);

    const sendCopy = data.copy ? ";" + user.email : "";

    // send email
    const email = {
      subject: subject,
      replyTo: user.email,
      intro: intro.split("<p><br></p>").join(""),
      introSecond: introSecond.split("<p><br></p>").join(""),
      recipient: data.recipient,
      cc: data.cc + sendCopy,
      user: user.firstName + " " + user.lastName,
      userId: user._id,
      clientId,
      clientRemoteId,
      clientSiren: props.clientSiren,
      firmId: accountingFirm._id,
      mcf: accountingFirm.mcf,
      color: accountingFirm.color || "#eaf4fe",
      iDepot: accountingFirm.iDepot,
      iDocus: accountingFirm.iDocus,
      conciliator: accountingFirm.conciliator,
      defaultView: props.type,
      missingDocView: client.missingDocView,
      type: buttonData.filter((elt) => elt.visible === true),
      ...(buttonData.find((el) => el.type === "outstandingsclients")?.refresh && {
        outstandingClientDueDay: client.outstandingsParam.clients.dueDays,
      }),
      ...(buttonData.find((el) => el.type === "outstandingsclients")?.refresh && {
        outstandingClientEndDate: client.outstandingsParam.clients.endDate || new Date(),
      }),
      ...(buttonData.find((el) => el.type === "outstandingsproviders")?.refresh && {
        outstandingProviderDueDay: client.outstandingsParam.providers.dueDays,
      }),
      ...(buttonData.find((el) => el.type === "outstandingsproviders")?.refresh && {
        outstandingProviderEndDate: client.outstandingsParam.providers.endDate || new Date(),
      }),
    };
    const res = await API.clientLinkMail(email);
    if (res.status === 200) {
      openNotification("success", "Votre mail a bien été envoyé.");
      props.setSelectedMailType("");
      setLoading(false);
      const resEmail = await res.json();
      dispatch(updateClient({ clientToken: resEmail.clientToken }));
    } else {
      openNotification("error", "Erreur lors de l'envoi du mail.");
      setLoading(false);
      return;
    }

    // refresh store
    const buildStoreEmail = {};
    const buildStoreClientPage = {};
    for (let i = 0; i < buttonData.length; i++) {
      const el = buttonData[i];
      if (el.refresh) {
        buildStoreEmail[el.type] = {
          ...(client.email?.[el.type] && client.email[el.type]),
          lastEmailSent: dayjs().toISOString(),
          recipient: data.recipient,
          cc: data.cc,
          prefSendCopy: data.copy,
        };
      }
      buildStoreClientPage[el.type] = {
        ...(client.clientPage?.[el.type] && client.clientPage[el.type]),
        display: el.visible,
      };
    }
    dispatch(
      updateClient({
        email: {
          ...client.email,
          ...buildStoreEmail,
        },
        clientPage: buildStoreClientPage,
      })
    );

    const filteredButtonData = buttonData.filter((elt) => elt.refresh === true);
    if (filteredButtonData.length === 3) logAction(245, 1, clientId);
    // backend actions
    filteredButtonData.forEach(async (tab) => {
      switch (tab.type) {
        case "missingdoc":
          console.log("missingdoc");
          logAction(81, 1, clientId);
          try {
            await API.postTagMissingDoc(clientId, {
              operationsToTag: [
                ...props.filteredOp.map((type) => type.data).flat(),
                ...props.others.flat(),
              ].map(({ _id }) => _id),
            });

            await API.putClient(clientId, {
              ["email." + tab.type]: {
                sender: user.email,
                lastEmailSent: dayjs(),
                recipient: data.recipient,
                cc: data.cc,
                prefSendCopy: data.copy,
              },
            });
            await API.postCollabTracker(clientId, {
              author: user.firstName + " " + user.lastName,
              action: "MISSING_DOC_MAIL",
              recipient: data.recipient ? data.recipient.split(";") : [],
              cc:
                data.cc || sendCopy
                  ? (data.cc + sendCopy).split(";").filter(function (item) {
                      return item !== "undefined";
                    })
                  : [],
              missingDoc: props.totaldoc,
              amount: parseFloat(props.amount.replace(/\s/g, "")),
            });
            API.postMissingDocTracker(clientId, {
              operations: props.filteredOp,
              others: props.others,
              collab: user.firstName + " " + user.lastName,
              recipient: data.recipient ? data.recipient.split(";") : [],
              cc:
                data.cc || sendCopy
                  ? (data.cc + sendCopy).split(";").filter(function (item) {
                      return item !== "undefined";
                    })
                  : [],
              commentCollab: props.filteredOp.map((com) =>
                com.operations.map(({ commentCollab }) => commentCollab)
              ),
            });
          } catch (error) {
            console.error(error);
          }
          break;

        case "outstandingsclients":
          console.log("clients");
          logAction(82, 1, clientId);
          try {
            await API.postTagOutstandings(clientId, {
              operationsToTag: props.selectedOperationsClients,
              type: "clients",
            });
            await API.putClient(clientId, {
              ["email." + tab.type]: {
                sender: user.email,
                lastEmailSent: dayjs(),
                recipient: data.recipient,
                cc: data.cc,
                prefSendCopy: data.copy,
              },
            });
            API.postCollabTracker(clientId, {
              author: user.firstName + " " + user.lastName,
              action: "OUTSTANDING_EMAIL_CLIENTS",
              recipient: data.recipient ? data.recipient.split(";") : [],
              cc:
                data.cc || sendCopy
                  ? (data.cc + sendCopy).split(";").filter((item) => item !== "undefined")
                  : [],
              quantity: props.emailData.find((elt) => elt.type === "outstandingsclients").nbr,
              amount: parseFloat(
                props.emailData
                  .find((elt) => elt.type === "outstandingsclients")
                  .amount.replace(/\s/g, "")
              ),
            });
          } catch (error) {
            console.error(error);
          }
          break;

        case "outstandingsproviders":
          logAction(83, 1, clientId);
          console.log("providers");
          try {
            await API.postTagOutstandings(clientId, {
              operationsToTag: props.selectedOperationsProviders,
              type: "providers",
            });
            await API.putClient(clientId, {
              ["email." + tab.type]: {
                sender: user.email,
                lastEmailSent: dayjs(),
                recipient: data.recipient,
                cc: data.cc,
                prefSendCopy: data.copy,
              },
            });
            API.postCollabTracker(clientId, {
              author: user.firstName + " " + user.lastName,
              action: "OUTSTANDING_EMAIL_PROVIDERS",
              recipient: data.recipient ? data.recipient.split(";") : [],
              cc:
                data.cc || sendCopy
                  ? (data.cc + sendCopy).split(";").filter((item) => item !== "undefined")
                  : [],
              quantity: props.emailData.find((elt) => elt.type === "outstandingsproviders").nbr,
              amount: parseFloat(
                props.emailData
                  .find((elt) => elt.type === "outstandingsproviders")
                  .amount.replace(/\s/g, "")
              ),
            });
          } catch (error) {
            console.error(error);
          }
          break;

        default:
          console.error("Unknown type: " + tab.type);
          break;
      }
    });

    // Reminder settings
    if (displayRelanceMail) {
      const myDate = dayjs(data.schedulingDateDatePicker)
        .hour(data.schedulingHourDatePicker.hour())
        .minute(0)
        .second(0)
        .millisecond(0);

      try {
        const reminderMail = {
          user: user.firstName + " " + user.lastName,
          userEmail: user.email,
          sendDate: myDate,
          recipient: data.recipient,
          cc: data.cc,
          prefSendCopy: data.copy,
          toSend: true,
          reminderMail: {
            subject: subjectReminder,
            linktext: "Accéder à la liste des justificatifs manquants",
            body: [
              {
                para: "begining",
                text: beginingReminder.split("<p><br></p>").join(""),
              },
              {
                para: "ending",
                text: endingReminder.split("<p><br></p>").join(""),
              },
            ],
          },
        };

        await API.putClient(clientId, {
          missingDocReminderMail: reminderMail,
        });
        dispatch(
          updateClient({
            missingDocReminderMail: reminderMail,
          })
        );
      } catch (error) {
        console.error(error);
      }
    } else if (client.missingDocReminderMail != null && showCheckRelanceMail) {
      try {
        await API.putClient(clientId, {
          missingDocReminderMail: null,
        });
        dispatch(
          updateClient({
            missingDocReminderMail: null,
          })
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  const ccInput = (
    <>
      <Form.Item name="cc" label="CC :" className="cc-item">
        <Input
          className={ccAlert ? "to comma-detected" : "custom-input-mail cc"}
          onChange={(e) => checkEmail(e, "cc")}
        />
      </Form.Item>
      <MinusCircleOutlined
        className="remove-cc"
        style={{ margin: "0 8px" }}
        onClick={() => {
          setCC(false);
        }}
      />
    </>
  );

  const springProps = useSpring({
    transformOrigin: "50% 50%",
    transform: isHovered ? `rotate(30deg)` : `rotate(0deg)`,
    config: {
      tension: 300,
      friction: 10,
    },
  });
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // ANTD 5.8.6 => needConfirm={false} not working, so we update the form value instead. Web in a nutshell SMH
  const onChangeTime = (time, timeString) => {
    form.setFieldValue("schedulingHourDatePicker", time);
  };

  useEffect(() => {
    form.setFieldsValue({
      size: componentSize,
      sender: "noreply@sesha.expert",
      recipient:
        props.type === "missingdoc"
          ? client.email?.[props.type]?.recipient
          : client.email?.["outstandings" + props.type]?.recipient,
      cc:
        props.type === "missingdoc"
          ? client.email?.[props.type]?.cc
          : client.email?.["outstandings" + props.type]?.cc,
      copy:
        props.type === "missingdoc"
          ? client.email?.[props.type]?.prefSendCopy
          : client.email?.["outstandings" + props.type]?.prefSendCopy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, props.type]);

  const showHasEditMail = () => {
    confirm({
      title: "",
      icon: <ExclamationCircleOutlined className="prompt-icon" />,
      className: "modal-invalid",
      maskClosable: false,
      closable: false,
      content: (
        <div className="prompt-text-style">
          <p>
            Attention ! Votre email est en cours d’édition. Si vous continuez, les modifications
            effectuées sur celui-ci seront perdues.
          </p>
          <p>Êtes-vous sûr de vouloir continuer ?</p>
          <div className="prompt-btns">
            <Button
              className="other-action-btn invalid-cancel"
              onClick={(e) => {
                e.preventDefault();
                Modal.destroyAll();
              }}
            >
              Annuler
            </Button>
            <Button
              className="call-action-btn"
              onClick={(e) => {
                e.preventDefault();
                Modal.destroyAll();
                setRefreshMail(true);
              }}
            >
              Continuer
            </Button>
          </div>
        </div>
      ),
    });
  };

  return (
    <>
      <div className="mail-analysis">
        <Form
          form={form}
          layout="vertical"
          onValuesChange={onFormLayoutChange}
          size={componentSize}
          name="email-form"
          onFinish={onSubmit}
        >
          <Row justify="center">
            <Col xs={22} sm={22} md={22} lg={22} xl={22} className="mail-box">
              <div style={{ position: "absolute", top: "10px" }}>
                <Popover
                  placement="bottom"
                  content={"Mettre à jour les données du mail"}
                  className="info-popover"
                  overlayClassName="navbar-popover-overlay"
                >
                  <Button
                    className="refresh-mail-btn"
                    shape="circle"
                    style={{
                      color: "black",
                      position: "fixed",
                      top: "14px",
                      marginLeft: "286px",
                    }}
                    onClick={() => {
                      if (hasEdit) showHasEditMail();
                      else setRefreshMail(true);
                    }}
                    icon={<SyncOutlined />}
                  />
                </Popover>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-mail-btn link-button call-action-btn send-mail-btn"
                    loading={loading}
                    icon={
                      <MailOutlined style={{ position: "absolute", bottom: "7px", left: "37px" }} />
                    }
                    disabled={testSkeleton ? true : false}
                  >
                    <span style={{ marginLeft: "20px" }}>Envoyer</span>
                  </Button>
                </Form.Item>
              </div>
              <MailSettings
                {...props}
                testSkeleton={(data) => {
                  if (hasEdit && !data) {
                    setHasEdit(false);
                  }
                  setTestSkeleton(data);
                }}
                buttonData={(data) => {
                  if (data !== buttonData) setButtonData(data);
                }}
                settings={buttonData}
                email={client.email}
                hasEdit={hasEdit}
                reminderMailSendDate={
                  client.missingDocReminderMail?.sendDate && client.missingDocReminderMail?.toSend
                    ? client.missingDocReminderMail?.sendDate
                    : undefined
                }
              />
              {buttonData ? (
                <>
                  {testSkeleton === false ? (
                    <>
                      {accountingFirm.proAbonoFeatures?.includes("outstandings") ? (
                        <hr className="hr-mail" />
                      ) : (
                        <p> </p>
                      )}
                      <Row className="mail-row">
                        <Col xs={22} sm={22} md={13} lg={13} xl={13}>
                          <Form.Item name="sender" label="De :">
                            <Input disabled className="form-input from" />
                          </Form.Item>
                          <Form.Item
                            name="recipient"
                            label="À (séparer les destinataires par un point-virgule) :"
                            rules={[
                              {
                                required: true,
                                message: "Veuillez renseigner un destinataire",
                              },
                            ]}
                          >
                            <Input
                              className={
                                recepientAlert ? "to comma-detected" : "custom-input-mail to"
                              }
                              onChange={(e) => checkEmail(e, "recepient")}
                            />
                          </Form.Item>
                          {!addCC ? (
                            <Button
                              className="other-action-btn add-cc"
                              onClick={() => {
                                setCC(true);
                              }}
                            >
                              Ajouter un destinataire en CC{" "}
                              <PlusCircleOutlined className="add-icon" />
                            </Button>
                          ) : (
                            ccInput
                          )}
                          <Form.Item name="copy" valuePropName="checked">
                            <Checkbox>Recevoir une copie</Checkbox>
                          </Form.Item>
                          {showEditSubject || !editSubject ? (
                            <div className="edit-btn">
                              <EditIcon />
                            </div>
                          ) : null}
                          {!editSubject ? (
                            <Button
                              icon={<CheckOutlined />}
                              className="validate-edit-btn"
                              onClick={() => {
                                setShowEditSubject(false);
                                setEditSubject(true);
                              }}
                            />
                          ) : null}
                          <Form.Item
                            name="subject"
                            className="form-subject"
                            label="Objet :"
                            rules={[{ message: "Merci de renseigner l'objet du mail" }]}
                          >
                            <div
                              onMouseOver={(e) => {
                                e.preventDefault();
                                setShowEditSubject(true);
                              }}
                              onMouseOut={(e) => {
                                e.preventDefault();
                                setShowEditSubject(false);
                              }}
                              onKeyDown={(e) => setHasEdit(true)}
                              onClick={(e) => setEditSubject(false)}
                            >
                              <Input
                                className="form-input subject"
                                value={subject}
                                onChange={(e) => {
                                  setSubject(e.target.value);
                                }}
                              />
                            </div>
                          </Form.Item>
                          <div className="mail-missing">
                            <div className="info-mail">
                              <InfoCircleOutlined style={{ color: "#a2b8ce" }} />
                              <div className="mail-info-select">
                                <div className="info-select-text">
                                  Pratique, complétez ou modifiez un paragraphe en cliquant sur le
                                  texte
                                </div>
                              </div>
                            </div>
                            {editShow || !editIntro ? (
                              <div className="edit-btn">
                                <EditIcon />
                              </div>
                            ) : null}
                            {!editIntro ? (
                              <Button
                                icon={<CheckOutlined />}
                                className="validate-edit-btn"
                                onClick={() => {
                                  setEditShow(false);
                                  setEditIntro(true);
                                }}
                              />
                            ) : null}
                            <div
                              className={
                                editShow && editIntro ? "intro-blue blue-edit" : "intro-blue"
                              }
                              onMouseOver={(e) => {
                                e.preventDefault();
                                setEditShow(true);
                              }}
                              onMouseOut={(e) => {
                                e.preventDefault();
                                setEditShow(false);
                              }}
                              onKeyDown={(e) => setHasEdit(true)}
                              onClick={(e) => setEditIntro(false)}
                            >
                              <Form.Item
                                name="intro"
                                className={!editIntro ? "text-tosend" : "test"}
                              >
                                <TextEditor
                                  {...props}
                                  text={intro}
                                  setText={(data) => setIntro(data)}
                                />
                                {/* <TextEditorAnalysis {...props} />*/}
                              </Form.Item>
                            </div>
                            <p className="fake-link">{link}</p>
                            {editShowSecond || !editIntroSecond ? (
                              <div className="edit-btn">
                                <EditIcon />
                              </div>
                            ) : null}
                            {!editIntroSecond ? (
                              <Button
                                icon={<CheckOutlined />}
                                className="validate-edit-btn"
                                onClick={() => {
                                  setEditShowSecond(false);
                                  setEditIntroSecond(true);
                                }}
                              />
                            ) : null}
                            <div
                              className={
                                editShowSecond && editIntroSecond
                                  ? "intro-blue blue-edit"
                                  : "intro-blue"
                              }
                              onMouseOver={(e) => {
                                e.preventDefault();
                                setEditShowSecond(true);
                              }}
                              onMouseOut={(e) => {
                                e.preventDefault();
                                setEditShowSecond(false);
                              }}
                              onClick={(e) => setEditIntroSecond(false)}
                            >
                              <Form.Item
                                name="introSecond"
                                className={!editIntroSecond ? "text-tosend" : "test"}
                              >
                                <TextEditor
                                  {...props}
                                  text={introSecond}
                                  setText={(data) => setIntroSecond(data)}
                                />
                                {/*<TextEditorAnalysis {...props} />*/}
                              </Form.Item>
                            </div>
                          </div>
                          <div className="edit-txt signature-txt">
                            {logo && user.signatureDisplayLogo ? (
                              <img src={logo} alt="logo" className="logo" />
                            ) : null}
                          </div>
                          <div className="edit-txt signature-txt">
                            {user.signatureImage && signatureImage ? (
                              <img
                                src={signatureImage}
                                alt="signature collaborateur"
                                style={{ maxWidth: "380px", maxHeight: "300px" }}
                              />
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <div
                        className="mail-row-explanation-box"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        <Row>
                          <Col span={1}>
                            <div className="mail-questionmark-icon">
                              <animated.span style={springProps} className="info-mail-icon">
                                <QuestionOutlined
                                  style={{ fontSize: "14px", transformOorigin: "0% 0%" }}
                                />
                              </animated.span>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="mail-row-explanation-box-content">
                              <p>
                                <EyeFilled className="mail-row-explanation-box-icons-1" />
                                Conserver l'onglet dans le formulaire (sur la base de la dernière
                                mise à jour)
                              </p>

                              <p>
                                <RedoOutlined className="mail-row-explanation-box-icons-1" />
                                Mettre à jour les données du formulaire
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                </>
              ) : null}
            </Col>
          </Row>

          {/* -------------------------- REMINDER MAIL -------------------------- */}
          {buttonData && showCheckRelanceMail && testSkeleton === false ? (
            <Row justify="center" style={{ marginBottom: "55px" }}>
              <Col xs={22} sm={22} md={22} lg={22} xl={22} className="mail-box-reminder">
                <Form.Item name="relanceMail" style={{ marginBottom: "0px" }}>
                  <Checkbox
                    value={displayRelanceMail}
                    onClick={(e) => {
                      //e.preventDefault();
                      setDisplayRelanceMail(!displayRelanceMail);
                    }}
                    defaultChecked={displayRelanceMail}
                  >
                    Programmer une relance automatique pour les pièces manquantes
                  </Checkbox>
                </Form.Item>
                {displayRelanceMail ? (
                  <>
                    <div className="sub-relance-mail">
                      Cette relance ne sera pas envoyée si :
                      <ul style={{ marginTop: "2px" }}>
                        <li>Il ne reste plus de points en suspens pour cette demande</li>
                        <li>Une autre demande est envoyée entre temps</li>
                      </ul>
                    </div>
                    <Row
                      style={{
                        margin: "15px 0px 15px 5%",
                        width: "fit-content",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "baseline" }}>
                        <span style={{ marginRight: "10px" }}>Le</span>
                        <Form.Item
                          name="schedulingDateDatePicker"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez renseigner une date",
                            },
                          ]}
                        >
                          <DatePicker locale={fr_FR} format={"DD/MM/YYYY"} placeholder="Date" />
                        </Form.Item>
                        <span style={{ margin: "0px 10px 0px 10px" }}>à</span>
                        <Form.Item
                          name="schedulingHourDatePicker"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez renseigner une heure",
                            },
                          ]}
                        >
                          <TimePicker
                            locale={fr_FR}
                            format={"HH:00"}
                            showNow={false}
                            changeOnBlur={true}
                            //needConfirm={false} // NOT WORKING as of 03/07/2024 with antd 5.8.6
                            //popupClassName="noFooterTimePick" // footer at display: none to hide the OK button
                            placeholder="Heure"
                          />
                        </Form.Item>
                      </div>
                    </Row>
                    <Row className="mail-row">
                      <Col xs={22} sm={22} md={13} lg={13} xl={13}>
                        {/* -------------------------- SUBJECT REMINDER MAIL -------------------------- */}
                        {showEditSubjectReminder || !editSubjectReminder ? (
                          <div className="edit-btn">
                            <EditIcon />
                          </div>
                        ) : null}
                        {!editSubjectReminder ? (
                          <Button
                            icon={<CheckOutlined />}
                            className="validate-edit-btn"
                            onClick={() => {
                              setShowEditSubjectReminder(false);
                              setEditSubjectReminder(true);
                            }}
                          />
                        ) : null}
                        <Form.Item
                          name="subjectReminder"
                          className="form-subject"
                          label="Objet :"
                          rules={[{ message: "Merci de renseigner l'objet du mail" }]}
                        >
                          <div
                            onMouseOver={(e) => {
                              e.preventDefault();
                              setShowEditSubjectReminder(true);
                            }}
                            onMouseOut={(e) => {
                              e.preventDefault();
                              setShowEditSubjectReminder(false);
                            }}
                            onKeyDown={(e) => setHasEdit(true)}
                            onClick={(e) => setEditSubjectReminder(false)}
                          >
                            <Input
                              className="form-input subject"
                              value={subjectReminder}
                              onChange={(e) => {
                                setSubjectReminder(e.target.value);
                              }}
                            />
                          </div>
                        </Form.Item>
                        {/* -------------------------- BEGINING REMINDER MAIL -------------------------- 
                        const [editBeginingReminder, setEditBeginingReminder] = useState(true);
                        const [editBeginingReminderShow, setEditBeginingReminderShow] = useState(false);
                      */}
                        <div className="mail-missing">
                          <div className="sub-balise-mail">
                            Les &lt;balises&gt; seront remplacées par les valeurs correspondantes au
                            moment de l'envoi.
                          </div>
                          {editBeginingReminderShow || !editBeginingReminder ? (
                            <div className="edit-btn">
                              <EditIcon />
                            </div>
                          ) : null}
                          {!editBeginingReminder ? (
                            <Button
                              icon={<CheckOutlined />}
                              className="validate-edit-btn"
                              onClick={() => {
                                setEditBeginingReminderShow(false);
                                setEditBeginingReminder(true);
                              }}
                            />
                          ) : null}
                          <div
                            className={
                              editBeginingReminderShow && editBeginingReminder
                                ? "intro-blue blue-edit intro-blue-reminder"
                                : "intro-blue intro-blue-reminder"
                            }
                            onMouseOver={(e) => {
                              e.preventDefault();
                              setEditBeginingReminderShow(true);
                            }}
                            onMouseOut={(e) => {
                              e.preventDefault();
                              setEditBeginingReminderShow(false);
                            }}
                            onKeyDown={(e) => setHasEdit(true)}
                            onClick={(e) => setEditBeginingReminder(false)}
                          >
                            <Form.Item
                              name="beginingReminder"
                              className={!editBeginingReminder ? "text-tosend" : "test"}
                            >
                              <TextEditor
                                {...props}
                                text={beginingReminder}
                                setText={(data) => setBeginingReminder(data)}
                              />
                            </Form.Item>
                          </div>

                          {/* -------------------------- LINK REMINDER MAIL -------------------------- */}
                          <p className="fake-link">{link}</p>

                          {/* -------------------------- ENDING REMINDER MAIL -------------------------- 
                            const [editEndingReminder, setEditEndingReminder] = useState(true);
                            const [editEndingReminderShow, setEditEndingReminderShow] = useState(false);
                        */}
                          {editEndingReminderShow || !editEndingReminder ? (
                            <div className="edit-btn">
                              <EditIcon />
                            </div>
                          ) : null}
                          {!editEndingReminder ? (
                            <Button
                              icon={<CheckOutlined />}
                              className="validate-edit-btn"
                              onClick={() => {
                                setEditEndingReminderShow(false);
                                setEditEndingReminder(true);
                              }}
                            />
                          ) : null}
                          <div
                            className={
                              editEndingReminderShow && editEndingReminder
                                ? "intro-blue blue-edit"
                                : "intro-blue"
                            }
                            onMouseOver={(e) => {
                              e.preventDefault();
                              setEditEndingReminderShow(true);
                            }}
                            onMouseOut={(e) => {
                              e.preventDefault();
                              setEditEndingReminderShow(false);
                            }}
                            onClick={(e) => setEditEndingReminder(false)}
                          >
                            <Form.Item
                              name="endingReminder"
                              className={!editEndingReminder ? "text-tosend" : "test"}
                            >
                              <TextEditor
                                {...props}
                                text={endingReminder}
                                setText={(data) => setEndingReminder(data)}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="edit-txt signature-txt">
                          {logo ? <img src={logo} alt="logo" className="logo" /> : null}
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Col>
            </Row>
          ) : null}
        </Form>
      </div>
    </>
  );
}

export default MailUnique;
